@tailwind base;
@tailwind components;
@tailwind utilities;

.lx-btn,
.lx-btnTwo[type='submit'],
.lx-btnTwo-sm[type='submit'],
.lx-btn-lg[type='submit'],
.lx-btn-sm[type='submit'],
.lx-btn[type='submit']{
    @apply
    lx-rounded-6
    lx-gap-2
    lx-font-[500]
    lx-text-[15px]
    lx-inline-flex
    lx-items-center
    lx-text-white
    lx-cursor-pointer
    lx-min-h-[40px]
    lx-p-[7px_20px]
    lx-justify-center
    lx-transition-all
    lx-outline-none
    lx-bg-theme_color
    lx-font-heading_font
    hover:lx-bg-theme_color
    hover:lx-text-white
    [&_svg_path]:lx-transition-all
    [&_svg_path]:lx-duration-300
    [&_.lx-spinner]:lx-w-[14px]
    [&_.lx-spinner]:lx-h-[14px]
    [&_.lx-spinner]:lx-rounded-[50%]
    disabled:!lx-bg-[#E5E7EB]
    disabled:!lx-text-[#4B5563]
    disabled:!lx-border-0
    disabled:hover:!lx-bg-[#E5E7EB]
    disabled:lx-pointer-events-none
    [&:disabled_svg_path]:!lx-fill-[#4B5563]
}
.lx-scroll-hover{
  @apply
  lx-scroll-smooth [scrollbar-width:auto] lx-transition-all lx-duration-[0.3s] lx-ease-[ease-in-out]
}
.lx-scroll-hover::-webkit-scrollbar {
  @apply
  lx-w-[5px] lx-transition-all lx-duration-[0.3s] lx-ease-[ease-in-out]
}
/* / Track / */
.lx-scroll-hover:hover::-webkit-scrollbar-track {
  @apply
  lx-bg-[transparent] lx-transition-all lx-duration-[0.3s] lx-ease-[ease-in-out]
}
/* / Handle / */
.lx-scroll-hover:hover::-webkit-scrollbar-thumb {
  @apply
  lx-bg-[#B9C6D0] lx-transition-all lx-duration-[0.3s] lx-ease-[ease-in-out] lx-rounded-[10px]
}
/* / Handle on hover / */
.lx-scroll-hover:hover::-webkit-scrollbar-thumb:hover {
  @apply
  lx-bg-[#B9C6D0]
}
.lx-invalid{
  @apply
  [&_input]:!lx-border-[#F04438]
  [&_textarea]:!lx-border-[#F04438]
  [&_.lx-datepicker]:lx-border-[#F04438]
  [&_em]:lx-flex
  [&_em]:lx-items-center
  [&_em]:lx-gap-[3.2px]
  [&_em]:lx-mt-[6px]
  [&_em]:lx-not-italic
  [&_em]:lx-text-[13px]
  [&_em]:lx-font-normal
  [&_em]:lx-leading-[16px]
  [&_em]:lx-text-[#F04438]
}
.lx-spinner {
  @apply
  lx-block
  lx-animate-[rotation_0.6s_infinite_linear_0.25s]
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading{
  color: white;
  font: 300 4em/150% Impact;
  text-align: center;
}
.loading:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 white;}
}
@keyframes dot-loading {
0% { opacity: 0; }
50% { opacity: 1; }
60% { opacity: 1; }
100% { opacity: 0; }
}

